import React, { useState } from "react"
//import GroupDetailsView from "../../components/media-output/lists/group-details-view/group-details-view"
import SearchGroupContent from "../../components/search/search-group-content/search-group-content"
//import SearchGroupEditContent from "../../components/search-groupedit-content"
import styles from "./index.module.scss"
import SearchLayout from "../../components/layout/search-layout/search-layout"
import auth from "../../services/auth"


const ViewMyGroups = props => {
  console.log('Search:', props.location.search)
  return (
    <SearchLayout location={props.location} searchPath="searchMine">
      <SearchGroupContent gid={props.location.search}></SearchGroupContent>
     
      {/* <GroupDetailsView></GroupDetailsView> */}
    </SearchLayout>
  )
}

export default ViewMyGroups
